<style scoped lang="less">
.t_selection {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
  .content {
    padding: 20px;
  }
  .resourceForm {
    padding: 0 20px;
  }
  .footer_drawer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .detailItem_title,
  .detailItem_content {
    padding: 0 20px 20px;
  }
  .detailItem_title {
    font-size: 18px;
    font-weight: bold;
  }
}
</style>
<template>
  <div class="t_selection">
    <div class="stu-module-header">
      <div class="stu-module-title">参考选题</div>
      <div>
        <el-button
          type="primary"
          @click="addResource"
          icon="el-icon-circle-plus-outline"
          >添加资源文件</el-button
        >
        <el-button type="primary" @click="del" icon="el-icon-remove-outline"
          >删除</el-button
        >
      </div>
    </div>
    <div class="content">
      <el-table
        :data="tableData"
        :height="height"
        @selection-change="selectionChange"
        stripe
      >
        <el-table-column
          type="selection"
          width="65"
          align="center"
        ></el-table-column>
        <el-table-column label="序号" width="65" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in column"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :min-width="item.width"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" width="80" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="onDetail(scope.row, scope.$index)">
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-drawer
      :visible.sync="resourceItem.drawer"
      size="50%"
      title="添加资源文件"
      :before-close="handleClose">
      <el-form
        :model="resourceItem.form"
        size="medium"
        :rules="resourceItem.rules"
        class="resourceForm"
        ref="resourceRef"
      >
        <el-form-item label="标题" prop="title">
          <QuillEditor
              @uploadFile1="uploadFile1"
              :resultMethods="'uploadFile1'"
            :paramsText="resourceItem.form.title"
            @quillEditorHtml="titleHtml"
          />
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <QuillEditor
              @uploadFile2="uploadFile2"
              :resultMethods="'uploadFile2'"
            :paramsText="resourceItem.form.content"
            @quillEditorHtml="contentHtml"
          />
        </el-form-item>
      </el-form>
      <div style="padding: 0 20px 20px">
        <div class="footer_drawer">
          <el-checkbox v-model="resourceItem.form.checked"
            >是否置顶</el-checkbox
          >
          <p>
            <el-button type="primary" @click="resourceConfirm">确 定</el-button>
            <el-button @click="resourceDrawer">取 消</el-button>
          </p>
        </div>
      </div>
    </el-drawer>

    <el-drawer :visible.sync="detailItem.drawer" size="50%">
      <div class="detailItem_content" v-html="detailItem.title">

      </div>
      <div class="detailItem_content" v-html="detailItem.content">

      </div>
    </el-drawer>
  </div>
</template>

<script>
import QuillEditor from "@/components/teacher/QuillEditor.vue";
export default {
  name: "t_selection",
  components: { QuillEditor },
  data() {
    return {
      height: window.innerHeight - 280,
      column: [
        { label: "标题", prop: "topicTitle", width: 200 },
        { label: "发布人", prop: "teacherName" },
        { label: "发布时间", prop: "createTime", width: 120 },
      ],
      selectionData: [],
      tableData: [
      ],
      total: 0,
      pageSize: 10,
      current: 1,

      resourceItem: {
        drawer: false,
        form: {
          title: null,
          content: null,
          checked: false,
        },
        rules: {
          title: [{ required: true, message: "标题不能为空", trigger: "blur" }],
          content: [
            { required: true, message: "内容不能为空", trigger: "blur" },
          ],
        },
      },
      detailItem: {
        drawer: false,
        title: null,
        content: null,
      },
    };
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    uploadFile2(str){
      if (null == this.resourceItem.form.content){
        this.resourceItem.form.content = str;
      }else {
        this.resourceItem.form.content = this.resourceItem.form.content+str;
      }
    },
    uploadFile1(str){
      if (null == this.resourceItem.form.title){
        this.resourceItem.form.title = str;
      }else {
        this.resourceItem.form.title = this.resourceItem.form.title+str;
      }
    },

    init() {
      this.openLoadingView();
      this.$axios_supermallData.get("/hnjxjy-core/teacherCourse/queryCourseTopic?courseId=" +this.course.id+ "&page=" + this.current + "&pageSize=" + this.pageSize + "&courseTopicType="+ 0)
          .then((resp) => {
            if (resp.data.code == 2000) {
              this.tableData = resp.data.data.list;
              this.tableData.forEach(t=>{
                this.$set(t,"topicTitleImg",t.topicTitle);
                this.$set(t,"topicInfoImg",t.topicInfo);
                let topicTitle = t.topicTitle.replace(/<[^>]+>|&[^>]+;/g,"").trim();
                let topicInfo = t.topicInfo.replace(/<[^>]+>|&[^>]+;/g,"").trim();
                this.$set(t,"topicTitle",topicTitle);
                this.$set(t,"topicInfo",topicInfo);
              })
              this.total = resp.data.data.total;
              this.loadingView.close();
            }else {
              this.loadingView.close();
            }
          }).catch((e)=> {
            this.$message.error(e)
            this.loadingView.close();
          })
    },
    // 添加资源文件
    addResource() {
      this.resourceItem.drawer = true;
    },
    // 添加资源文件 确定
    resourceConfirm() {
      this.$refs["resourceRef"].validate((valid) => {
        if (!valid) return false;
        this.openLoadingView();
        let topicTitle = this.resourceItem.form.title;
        let topicInfo = this.resourceItem.form.content;
        let data = {
          topicTitle: topicTitle, //标题
          courseId: this.course.id, //课程id
          topicInfo: topicInfo, //内容
          courseTopicType: 0 //类型 0参考选题 1课程文件
        }
        this.$axios_supermall.
        post("/hnjxjy-core/teacherCourse/addCourseTopicByCourseId",data)
            .then((resp) => {
              if (resp.data.code == 2000) {
                this.$message.success("添加成功");
                this.init();
              }else {
                this.$message.error("添加失败");
                this.loadingView.close();
              }
            }).catch((e)=>{
              this.$message.error(e);
              this.loadingView.close();
            })
        this.resourceItem.form = {
          title: null,
          content: null,
          checked: false,
        }
        this.resourceItem.drawer = false;
      }).catch((e)=> {
      })
    },
    resourceDrawer(){
      this.resourceItem.form = {
        title: null,
        content: null,
        checked: false,
      }
      this.resourceItem.drawer = false;
    },
    // 删除
    del() {
      if (this.selectionData.length < 1){
        return  this.$message.error("请选择需要删除的数据");
      }
      this.openLoadingView();
      let idArray = [];
      for (let i = 0 ; i < this.selectionData.length ; i++){
        idArray.push(this.selectionData[i].id)
      }
      let ids = idArray.toString();
      this.$axios_supermallData({
        url: "/hnjxjy-core/teacherCourse/updateCourseTopicById",
        method: 'get',
        params: {
          ids: ids
        }
      }).then((resp) => {
        if (resp.data.code == 2000) {
          this.$message.success("删除成功");
          this.init();
        }else {
          this.$message.error("删除失败");
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e);
        this.loadingView.close();
      })
    },
    // 查看
    onDetail(row, index) {
      if (row) {
        this.detailItem = {
          title: row.topicTitleImg,
          content: row.topicInfoImg,
          drawer: true,
        };
      }
    },
    selectionChange(val) {
      this.selectionData = val;
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },

    titleHtml(html) {
      this.resourceItem.form.title = html;
    },
    contentHtml(html) {
      this.resourceItem.form.content = html;
    },
    handleClose(done){
      done();
      this.resourceItem.form = {
        title: null,
        content: null,
        checked: false,
      }
    }
  },
};
</script>
